

import React, { Suspense, useContext, useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import NotFound from '../components/notFound/notFound';
import { ToastContainer } from 'react-toastify';
import Loader from '../components/loader/loader';
import { UserContext } from '../context/theme';
import SiderMenu from './siderMenu';
import Header from './header';
import FooterUnAuth from '../unAuth/footerUnAuth';
import Redirect from './redirect';
import { socketConnectionURL } from '../services/api-url';
import io from "socket.io-client";
import "react-datepicker/dist/react-datepicker.css";




const Dashboard = React.lazy(() => import('../pages/dashboard/dashboard'));
const Notification = React.lazy(() => import('../pages/notification'));

const Subscription = React.lazy(() => import('../pages/subscription/Subscription'));
const Success = React.lazy(() => import('../pages/subscription/Success'));
const Failure = React.lazy(() => import('../pages/subscription/Failure'));


const ManageStore = React.lazy(() => import('../pages/manageStore/ManageStore'));
const CoverImage = React.lazy(() => import('../pages/manageStore/CoverImage'));

const PreferVendorList = React.lazy(() => import('../pages/preferVendor/PreferVendorList'))
const MyWork = React.lazy(() => import('../pages/mywork/list'))
const MyWorkAdd = React.lazy(() => import('../pages/mywork/add'))
const MyWorkGallery = React.lazy(() => import('../pages/mywork/gallery'))

// Product / Service
const ProductAdd = React.lazy(() => import('../pages/product/ProductAdd'));
const ProductList = React.lazy(() => import('../pages/product/ProductList'));
const ProductClicks = React.lazy(() => import('../pages/product/ProductClicks'));
const ReviewRating = React.lazy(() => import('../pages/product/reviewRating'));

const TopSpot = React.lazy(() => import('../pages/topSpot/TopSpot'));

// Brochure
const BrochuresList = React.lazy(() => import('../pages/brochures/BrochuresList'));
const BrochuresAdd = React.lazy(() => import('../pages/brochures/BrochuresAdd'));

// Orders
const OrderList = React.lazy(() => import('../pages/order/OrderList'));
const OrderView = React.lazy(() => import('../pages/order/OrderView'));
const BulkOrderList = React.lazy(() => import('../pages/order/BulkOrderList'));
const ServiceList = React.lazy(() => import('../pages/order/serviceList'));
const StoreInquiryList = React.lazy(() => import('../pages/order/storeContacted'));

// Promo
const PromoList = React.lazy(() => import('../pages/promo/PromoList'));
const PromoAdd = React.lazy(() => import('../pages/promo/PromoAdd'));

const Profile = React.lazy(() => import("../pages/profile/profile"))
const Setting = React.lazy(() => import("../pages/Setting"))


const Report = React.lazy(() => import("../pages/reports/Report"))

// Information
const PrivacyPolicy = React.lazy(() => import('../pages/privacyPolicy'));
const TermCondition = React.lazy(() => import('../pages/termCondition'));
const ContactUs = React.lazy(() => import('../pages/contactUs'));
const Faq = React.lazy(() => import('../pages/faq'));
const AboutUs = React.lazy(() => import('../pages/aboutUs'));

// Help
const Help = React.lazy(() => import('../pages/helpdesk/help'));
const HelpTicketView = React.lazy(() => import('../pages/helpdesk/helpTicketView'));
const RaiseTicket = React.lazy(() => import('../pages/helpdesk/raiseTicket'));



const DashLayout = ({ Component }) => {
  const context = useContext(UserContext);
  const { auth, setSocket, userInfo } = context
  const authLocal = localStorage.getItem("token");
  useEffect(() => {
    if (auth && authLocal != undefined) {
      const connection = io(`${socketConnectionURL}?type=vendor&token=${authLocal}`, { transports: ["websocket", "polling"] });
      setSocket(connection || {})

      connection.on("connect", () => {
        // setIsConnected(true);
        // socket.emit("joinRoom", { ticketNumber: "" });
      });

      connection.on("disconnect", () => {
        // setIsConnected(false);
      });
      return () => {
        connection.off("connect");
        connection.off("disconnect");
      };
    }


  }, [auth, authLocal]);
  return (
    <div>
      <div className={`page-wrapper 
      ${context.Theme} 
      ${context.ThemeColor} 
      ${context.MiniSidebar ? 'mini-sidebar' : ''}      
    `}>

        <div className="all-content-wrapper">
          <SiderMenu />
          <div className="mainContent">
            <div className="container-fluid">
              <Loader />
              <Suspense fallback={<div>Loading...</div>}>
                <Routes>

                  <Route path="/notification" element={<Notification />} />





                  {/* FBi*/}
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/:type/clicks" element={<ProductClicks />} />
                  {/* <Route path="/top-spot" element={<TopSpot />} /> */}
                  <Route path="*" element={<NotFound />} />

                  <Route path="/manage/store" element={<ManageStore />} />
                  <Route path="/manage/store/cover-image" element={<CoverImage />} />

                  <Route path='/preferVendor' element={<PreferVendorList />} />
                  <Route path='/mywork/add' element={<MyWorkAdd />} />
                  <Route path='/mywork/edit/:id' element={<MyWorkAdd />} />
                  <Route path='/mywork/list' element={<MyWork />} />
                  <Route path='/mywork/gallery/:id' element={<MyWorkGallery />} />

                  {/* type (product/service) */}
                  <Route path="/:type/list" element={<ProductList />} />
                  <Route path="/:type/add" element={<ProductAdd />} />
                  <Route path="/:type/edit/:id" element={<ProductAdd />} />
                  <Route path="/review-rating/:type/:ratingId" element={<ReviewRating />} />

                  {/* Brochure */}
                  <Route path="/brochure/list" element={<BrochuresList />} />
                  <Route path="/brochure/add" element={<BrochuresAdd />} />
                  <Route path="/bulk-order/inquiry" element={<BulkOrderList />} />
                  <Route path="/:type/inquiry" element={<ServiceList />} />
                  <Route path="/store/view/inquiry" element={<StoreInquiryList />} />

                  {/* orders */}
                  <Route path="/order/list" element={<OrderList />} />
                  <Route path="/order/view/:id" element={<OrderView />} />

                  {/* Subscription */}
                  <Route path="/subcription" element={<Subscription />} />
                  <Route path="/subcription/success/:id" element={<Success />} />
                  <Route path="/subcription/failure/:id" element={<Failure />} />

                  {/* Promocode */}
                  <Route path="/promocode" element={<PromoList />} />
                  <Route path="/promocode/add" element={<PromoAdd />} />
                  <Route path="/promocode/edit/:id" element={<PromoAdd />} />

                  {/* Profile */}
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/setting" element={<Setting />} />

                  {/* help */}
                  <Route path="/help" element={<Help />} />
                  <Route path="/helpTicketView/:ticketId" element={<HelpTicketView />} />
                  <Route path="/raiseTicket" element={<RaiseTicket />} />


                  {/* Transaction Report */}
                  <Route path="/report" element={<Report />} />



                </Routes>
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const MainLayout = ({ Component }) => {
  const { Theme, ThemeColor } = useContext(UserContext);


  return (
    <div>
      <div className={`page-wrapper ${Theme} ${ThemeColor} pageLogin`}>
        <div className="login-wrapper">
          {/* <div className="all-content-wrapper"> */}
          {/* <TopNavbar/>  */}
          <Loader />
          {/* <Header /> */}
          <SiderMenu />
          <div className="saledeskWRP">
            <Routes>
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/terms-conditions" element={<TermCondition />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

function Layout() {
  const [layoutType, setLayoutType] = useState(false);
  const context = useContext(UserContext);
  const { pathname } = useLocation();
  const publicPath = ["/about-us", "/contact-us", "/terms-conditions", "/faq", "/privacy-policy"];

  useEffect(() => {
    let check = publicPath.includes(pathname);
    context.setGlobalLayout(check)
    setLayoutType(check);
  }, [pathname]);


  return (
    <div>
      <ToastContainer />
      <Header />
      <Suspense fallback={<Loader />}>{layoutType ? <MainLayout /> : <DashLayout />}</Suspense>
      <FooterUnAuth />
    </div>
  );
}
export default Layout;
