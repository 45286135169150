import React from 'react'
import { Link } from 'react-router-dom'

export default function FooterUnAuth() {
    return (
        <>
            <footer className='footerwrp'>
                <div className="container-fluid">
                    <div className="footerwrpcon">
                        <div className='row justify-content-center'>
                            <div className='col-lg-12'>
                                <div className="footerlogo"><img className='img-fluid' src="/assets/images/logo.png" alt="images" /></div>
                            </div>
                            {/* <div className='col-xl-3 col-md-12 col-sm-12'>
                                <div className="footerUlwrp">
                                    <ul className='footerulFrt'>
                                        <li className='footerheadtxt'>About Us</li>
                                        <li><p className='m-0'>We are a group of committed
                                            individuals with a mission to
                                            build innovative solutions to
                                            solve B2B commerce problems.
                                        </p></li>
                                    </ul>
                                </div>
                            </div> */}
                            <div className='col-xl-12 col-md-12 col-sm-12'>
                                <div className="footerUlwrp">
                                    <ul>
                                        <li className='footerheadtxt'>Contact Us</li>
                                        <li> <a href="mailto:foundationbrickindia@gmail.com"><p>foundationbrickindia@gmail.com</p></a ></li>
                                        <li className='text-white'> <a href="tel:+918950919508">+91-8950919508</a>  </li>
                                    </ul>
                                    <ul>
                                        <li className='footerheadtxt'>Information</li>
                                        <li><Link to="/about-us">About Us</Link></li>
                                        <li><Link to="/contact-us">Contact Us</Link></li>
                                        <li><Link to="/faq">FAQ’s</Link></li>
                                    </ul>
                                    <ul>
                                        <li className='footerheadtxt'>Helpful Link’s</li>

                                        <li><Link to="/terms-conditions">Terms & Conditions</Link></li>
                                        <li><Link to="privacy-policy">Privacy Policy</Link></li>
                                    </ul>
                                    <ul>
                                        <li className='footerheadtxt follow'>Follow Us :</li>
                                        <div className="followUsimg">
                                            <li><a href="https://www.linkedin.com/in/foundation-group-b185b4271" target="_blank"> <img className='img-fluid' src="/assets/images/img7.svg" alt="image" /> </a></li>
                                            <li><a href="https://instagram.com/fbi_upsol?igshid=MzRlODBiNWFlZA==" target="_blank"><img className='img-fluid' src="/assets/images/img2.svg" alt="image" /></a></li>
                                            <li><a href="https://www.facebook.com/foundation321?mibextid=ZbWKwL" target="_blank" ><img className='img-fluid' src="/assets/images/img3.svg" alt="image" /></a></li>
                                            <li><a href="https://pin.it/4fV97d9" target="_blank"><img className='img-fluid' src="/assets/images/Pinterest.png" alt="image" /></a></li>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footercpcon">©Copyright Foundation Brick Of India</div>
            </footer>
        </>
    )
}
