
import React, { Suspense, useContext, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Loader from '../components/loader/loader';
import SuccessModal from '../components/modals/successModal';
import NotFound from '../components/notFound/notFound';
import { UserContext } from '../context/theme';

import FooterUnAuth from './footerUnAuth';
import HeaderUnAuth from './headerUnAuth';
import { useEffect } from 'react';

const TermCondition = React.lazy(() => import('../pages/termCondition'));
const PrivacyPolicy = React.lazy(() => import('../pages/privacyPolicy'));
const ContactUs = React.lazy(() => import('../pages/contactUs'));
const AboutUs = React.lazy(() => import('../pages/aboutUs'));
const VerifyOTP = React.lazy(() => import('../components/login/VerifyOTP'));
const SignUp = React.lazy(() => import('../components/login/signUp'));
const GetOTP = React.lazy(() => import('../components/login/signIn'));
const Faq = React.lazy(() => import('../pages/faq'));

function UnAuth() {

  const { Theme, ThemeColor } = useContext(UserContext);



  return (
    <div>
      <ToastContainer />
      <div className={`page-wrapper ${Theme} ${ThemeColor} pageLogin`}>
        <div className="login-wrapper">
          <Loader />
          {/* <div className="all-content-wrapper"> */}
          {/* <TopNavbar/>  */}
          <SuccessModal />
          <HeaderUnAuth />
          <div className="saledeskWRP">
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route path="/" element={<GetOTP />} />
                <Route path="/verifyOTP" element={<VerifyOTP />} />
                <Route path="/signUp" element={<SignUp />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/terms-conditions" element={<TermCondition />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Suspense>
          </div>
          <FooterUnAuth />
        </div>
      </div>
      {/* </div> */}
    </div>

  );
}

export default UnAuth;
