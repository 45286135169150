import React, { useContext } from 'react'
import { useEffect } from 'react';
import UnauthAxios from '../../services/unauthAxios';
import { UserContext } from '../../context/theme';

function Translator({ mobile }) {
  const { gscriptAdded, setGscriptAdded } = useContext(UserContext)

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en', includedLanguages: 'en,hi,te,gu,ta,bn,mr,ur,pa',
        autoDisplay: true
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    let addScript = document.createElement("script");
    if (!gscriptAdded) {
      addScript.setAttribute(
        "src",
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
      );
      document.body.appendChild(addScript);
      window.googleTranslateElementInit = googleTranslateElementInit;
      setGscriptAdded(true)
    }
  }, [gscriptAdded]);

  return (
    <div className="tabContent ">
      <form >
        <div className="homenotiinputCon ">
          <div className="row ">
            <div className="col-lg-12 col-12">
              {/* <div className="homenoticallbackcon">Languages </div> */}

              <div className="homeinptabcon">

                <div id="google_translate_element"></div>



              </div>
            </div>
          </div>

        </div>
      </form>

    </div>
  )
}

export default Translator