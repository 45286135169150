import { toast } from "react-toastify";
import { reactLocalStorage } from "reactjs-localstorage";
import * as Yup from "yup";
import apiFunc from "./api";
import millify from "millify";
import authAxios from "./authAxios";
import moment from "moment";


const common = {
    milifying: (milifyData) => {
        return millify(milifyData, {
            units: ["", "K", "M", "B", "T", "P", "E"],
            space: true,
        });
    },
    loader: (type) => {
        if (type) {
            document.body.className = "loading_page";
        } else {
            document.body.className = document.body.className.replace("loading_page", "");
        }
    },
    base64Mime: (encoded) => {
        var result = null;
        if (typeof encoded !== "string") {
            return result;
        }
        var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
        if (mime && mime.length) {
            result = mime[1];
        }
        return result;
    },
    base64ReadFile: (base64String) => {
        var nonBlob = base64String == undefined ? 0 : base64String.length;
        var filetype = common.base64Mime(base64String);
        var datass = { size: nonBlob / 1000, type: filetype };
        return datass;
    },
    previewURL: (file) => {
        let URL = "";
        if (file != "") {
            URL = window.URL.createObjectURL(file);
        }
        return URL;
    },
    mineTypeValidate: (value) => {
        if (value == undefined || value == null) {
            return false;
        }
        let fileType = value.type;
        return (
            value && (fileType === "image/jpeg" || fileType === "image/bmp" || fileType === "image/png") /* ||
      fileType === 'application/pdf' ||
      fileType === "application/msword" */
        );
    },
    fileSizeValidate: (value, size) => {
        if (value == undefined || value == null) {
            return false;
        }
        let fileSize = value.size;
        if (!fileSize) {
            fileSize = 2;
        }
        let mb = fileSize * 1024;
        return fileSize <= mb;
    },
    getMiles: (i) => {
        return (i * 0.000621371192).toFixed(1);
    },
    coupanTypeDiscount: (obj) => {
        let ctype = obj.couponType || 0;
        let price = obj.price || 0;
        let discount = obj.discount || 0;
        let minAmount = obj.minAmount || 0;
        let disUpto = obj.disUpto || 0;
        let disRate = 0;
        if (ctype == "FLAT PERCENT") {
            disRate = price >= minAmount ? (price * discount) / 100 : disRate;
        } else if (ctype == "FLAT PERCENT UPTO") {
            disRate = price >= minAmount ? (price * discount) / 100 : disRate;
            disRate = disRate <= disUpto ? disRate : disUpto;
            // console.log("FLAT PERCENT UPTO", disRate);
        } else if (ctype == "CASH DISCOUNT UPTO") {
            disRate = price >= minAmount ? discount : disRate;
            disRate = disRate <= disUpto ? disRate : disUpto;
            // console.log("CASH DISCOUNT UPTO", disRate);
        }
        return parseFloat(disRate.toFixed(2));
    },
    isMobile: (num) => {
        var isphone = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(num);
        return isphone;
    },
    coordinateLocal: async () => {
        let coordataL;
        function asignData(data) {
            coordataL = data;
        }
        await navigator.geolocation.getCurrentPosition(function (position) {
            let lat = position.coords.latitude;
            let lng = position.coords.longitude;
            let jsonCoords = {
                lat: lat,
                lng: lng,
            };
            jsonCoords = JSON.stringify(jsonCoords);
            asignData(jsonCoords);
            localStorage.setItem("geoLocal", jsonCoords);
            // console.log('coordataL', localStorage.getItem('geoLocal'))
            // reactLocalStorage.set('geoLocal', jsonCoords);
        });
        return coordataL;
    },
    creditCardType: (cardType) => {
        let imageUrl;
        cardType = cardType.toLowerCase();
        switch (cardType) {
            case "visa":
                imageUrl = "card-logo-visa.svg";
                break;
            case "mastercard":
                imageUrl = "card-logo-mastercard.svg";
                break;
            case "american-express":
                imageUrl = "card-logo-amex.svg";
                break;
            default:
                imageUrl = "card-logo-unknown.svg";
        }
        return imageUrl;
    },
    imageValidate: (extention, size, msg, typeMsg) => {
        extention = extention || ["image/jpg", "image/jpeg", "image/png"];
        msg = msg || "You need to attach image";
        size = size || 4 * 1024 * 1024;
        typeMsg = typeMsg || `Only the following formats are accepted: ${extention?.join(", ")}`;
        let image = Yup.mixed()
            .test("fileSize", "The image is too large", (value) => {
                if (value == undefined || value == null) {
                    return false;
                }
                return value && value?.size <= size;
            })
            .test("type", typeMsg, (value) => {
                if (value == undefined || value == null) {
                    return false;
                }
                let fileType = value.type;
                return value && extention.indexOf(fileType) != -1;
            });
        return image;
    },
    imageValidate2: (extention, size, msg, typeMsg) => {
        extention = extention || ["image/jpg", "image/jpeg", "image/png"];
        msg = msg || "You need to attach image";
        size = size || 4 * 1024 * 1024;
        typeMsg = typeMsg || `Only the following formats are accepted: ${extention?.join(", ")}`;
        let image = Yup.mixed()
            .test("fileSize", "The file is too large. Maximum allow sie is 5MB", (value) => {
                if (value == undefined || value == null) {
                    return false;
                }
                return value && value?.size <= size;
            })
            .test("type", typeMsg, (value) => {
                if (value == undefined || value == null) {
                    return false;
                }
                let fileType = value.type;
                return value && extention.indexOf(fileType) != -1;
            });
        return image;
    },

    userimageValidate: (value, ext, sizeFile) => {
        let extention = ext ? ext : ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf']
        let size = sizeFile ? (parseInt(sizeFile) * 1024 * 1024) :( 4 * 1024 * 1024);
        let temp = true
        if (!extention?.includes(value.type)) {
            toast.error(`Only the following formats are accepted: ${extention?.join(", ")}`)
            temp = false
        }
        if (value?.size > size) {
            toast.error("Image size is too large")
            temp = false
        }
        return temp

    },
    


    uploadImage: async (values, allResponse) => {
        let data;
        common.loader(true);
        const formData = new FormData();
        formData.append("coverImage", values.userImage);

        data = await authAxios({
            method: "POST",
            url: `/media/upload`,
            data: formData,
        })
            .then((res) => {
                if (allResponse) {
                    return res;
                } else {
                    return res.data.data;
                }
            })
            .catch((error) => {
                common.error(error);
            });
        common.loader(false);
        return data;
    },
    error: (error) => {
        var message = JSON.parse(error.request.response).message;

        toast.error(message);
        return message;
    },

    generateQOID: async () => {
        let resData = null;
        resData = await apiFunc
            .getOptionId()
            .then((res) => {
                return res.data.data;
            })
            .catch((error) => {
                common.error(error);
            });

        return resData;
    },
    bufferToBase64: (arr) => {
        //arr = new Uint8Array(arr) if it's an ArrayBuffer
        let buffer = btoa(arr.reduce((data, byte) => data + String.fromCharCode(byte), ""));
        return "data:image/png;base64," + buffer;
    },
    getImage: async (id) => {
        if (id) {
            let data;
            data = await apiFunc
                .getImage(id)
                .then((res) => {
                    return common.bufferToBase64(res.data.data.Body.data);
                })
                .catch((error) => {
                    common.error(error);
                });
            return data;
        }
    },
    aadharValidate: (num) => {
        let regexp = /^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/;
        return regexp.test(num);
    },
    isAlphabet: (event) => {
        // console.log(event)
        return (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122);
    },

    inpAlphabet: (e) => {
        let realphate = e.replace(/[^A-Za-z\s]/gi, "");
        return realphate;
    },

    isPrice: (val) => {
        val = val.replace(/[^0-9\.]/g, '');
        let spiltData = val?.split(".")

        if (spiltData?.length > 2) {
            val = val?.replace(/\.+$/, "");
        }
        if (spiltData[1]?.length > 2) {
            val = Number(val?.toString()?.slice(0, -1))
        }
        return val
    },


    alphaNumber: (e) => {
        let alprealphate = e.replace(/[^\w\s]/gi, "");
        return alprealphate;
    },
    dateDefferance: (date) => {
        let addDate = date - 1
        const date1 = new Date();
        const date2 = new Date(addDate);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return {
            times: diffTime,
            days: diffDays,
        };
    },


    remainingDays: (date_1, date_2 = new Date()) => {
        date_1 = new Date(date_1.setHours(23, 59, 59, 999))
        let difference = date_1.getTime() - date_2.getTime();
        let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
        return TotalDays;
    },

    addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    },
    getFirstLetter: (str) => {
        if (str) {
            let matches = str?.match(/\b(\w)/g);
            let strJoin = matches?.join("");
            let letters = strJoin?.charAt(0);
            let letters2 = strJoin?.charAt(1);
            return letters + letters2;
        }
    },
    getProductFilter: () => {
        const params = new URLSearchParams(window.location.search);
        let page = params.get("page");
        let searchString = params.get("searchString");
        let basicPrice = params.get("basicPrice") || "";
        let status = params.get("status") || "";
        let categoryId = params.get("categoryId") || "";
        let newPostData = {};
        newPostData.page = page || 1;
        newPostData.perPage = 10;
        newPostData.searchString = searchString || "";
        newPostData.basicPrice = basicPrice || "";
        newPostData.status = status;
        newPostData.categoryId = categoryId || "";
        return newPostData;
    },
    getInviteFilter: () => {
        const params = new URLSearchParams(window.location.search);
        let page = params.get("page");
        let searchString = params.get("searchString");
        let startDate = params.get("startDate");
        let endDate = params.get("endDate");
        let newPostData = {};
        newPostData.page = page || 1;
        newPostData.perPage = 10;
        newPostData.searchString = searchString || "";
        newPostData.startDate = startDate || "";
        newPostData.endDate = endDate || "";

        return newPostData;
    },
    getActiveFilter: () => {
        const params = new URLSearchParams(window.location.search);
        let page = params.get("page");
        let searchString = params.get("searchString");
        let natureOfBusiness = params.get("natureOfBusiness");
        let city = params.get("city");
        let sorting = params.get("sorting");
        let newPostData = {};
        newPostData.page = page || 1;
        newPostData.perPage = 10;
        newPostData.searchString = searchString || "";
        newPostData.natureOfBusiness = natureOfBusiness || "";
        newPostData.city = city || "";
        newPostData.sorting = sorting || "new";

        return newPostData;
    },
    getCatalogueFilter: () => {
        const params = new URLSearchParams(window.location.search);
        let page = params.get("page");
        let searchString = params.get("searchString");
        let sorting = params.get("sorting");
        let newPostData = {};
        newPostData.page = page || 1;
        newPostData.perPage = 10;
        newPostData.searchString = searchString || "";
        newPostData.sorting = sorting || "new";
        return newPostData;
    },
    getFilter: () => {
        const params = new URLSearchParams(window.location.search);
        let page = params.get("page");
        let searchString = params.get("searchString");
        let newPostData = {};
        newPostData.page = page || 1;
        newPostData.perPage = 10;
        newPostData.searchString = searchString || "";
        return newPostData;
    },
    trim: (d) => {
        if (d == undefined || d == null || d == "") {
            return;
        }
        return d.replace(/(<([^>]+)>)/gi, " ");
    },
    dateFormate: (formate, date) => {
        if (date) {
            let newdate = moment(date).format(formate || "DD-MM-YYYY");
            return newdate;
        }
    },
    rangMinDate: (date) => {
        if (date) {
            let newdate = new Date(date);
            return newdate;
        } else {
            return new Date('2023-01-01')
        }
    },
    rangMaxDate: (date) => {
        if (date) {
            let newdate = new Date(date);
            return newdate;
        } else {
            return new Date()
        }
    },

    capitalizeWord: (str) => {
        let splitStr = str?.toLowerCase()?.split(" ")
        for (let i = 0; i < splitStr?.length; i++) {
            splitStr[i] = splitStr[i]?.charAt(0)?.toUpperCase() + splitStr[i]?.substring(1)
        }
        return splitStr?.join(" ")
    },

    perPageData: () => {
        return 10
    },

    serviceAddInitial: () => {
        let initial = {
            name: "",
            subCategory: "",
            category: "",
            description: "",
            basicPrice: '',
            salePrice: '',
            unitId: "",
            gst: '',
            images: [],
            brand: "",
            status: true,
            keywords:[],
            isPinned: false
        }
        return initial
    },

    serviceValidaton: () => {

        let serviceValidatonSchema = Yup.object().shape({
            name: Yup.string().required("Please enter name"),
            category: Yup.string().required("Please select category"),
            subCategory: Yup.string().required("Please select sub category"),
            keywords: Yup.array().of(Yup.string()).required("Please enter keywords"),
            isPinned: Yup.boolean().required("Pinned is required"),
            // description: Yup.string().required("Please enter description"),
            basicPrice: Yup.string().test('salePrice', "Charge must be greater than discount charge", (values, data) => {
                if (data.parent.salePrice) {
                    if (common.handGstORamount(data.parent.salePrice, data.parent.gst) <= Number(values)) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return true
                }
            }),
            salePrice: Yup.string().required("Please enter discount charge"),
            unitId: Yup.string().required("Please select unit"),
            gst: Yup.string().required("Please select gst"),
            // brand: Yup.string().required("Please enter keyword/similar word"),
            images: Yup.array()
                .of(Yup.string().required("Please choose images"))
                .min(1, "Please choose atleast one service image")
                .required("Please choose atleast one service image"),
        })

        return serviceValidatonSchema
    },
    handGstORamount :(saleprice, gst)=>{
        let tax = (Number(saleprice || 0) * Number(gst || 0))/100
        let withTaxamount = Number(saleprice || 0) + Number(tax || 0)
        // console.log(saleprice, gst, tax, withTaxamount)
        return withTaxamount || ''
    },
    productAddInitial: () => {
        let initial = {
            name: "",
            subCategory: "",
            category: "",
            description: "",
            sku: "",
            hsn: "",
            basicPrice: '',
            salePrice: '',
            unitId: "",
            gst: "",
            brand: "",
            stockStatus: true,
            isGolden: false,
            moq: '',
            allowReturn: false,
            images: [],
            enquireOnly:false,
            multiVariant:false,
            variants:[
                {
                    size:'',
                    description:'',
                    colors:[{
                        name:'',
                        basicPrice:'',
                        salePrice:'',
                        images:[],
                    }],
                }
            ],
            keywords:[],
            isPinned:false,
        }
        return initial
    },

    ProductValidaton: () => {

        let ProductValidatonSchema = Yup.object().shape({
            name: Yup.string().required("Please enter name"),
            category: Yup.string().required("Please select category"),
            subCategory: Yup.string().required("Please select sub category"),
            keywords: Yup.array().of(Yup.string()).required("Please enter keywords"),
            isPinned: Yup.boolean().required("Pinned is required"),
            // description: Yup.string().required("Please enter description"),
            sku: Yup.string().required("Please enter sku"),
            hsn: Yup.string().required("Please enter hsn code"),
            salePrice: Yup.string().test("check",(values, {parent, createError})=>{
                if(parent?.multiVariant){
                    return true
                }else{
                    if(values){
                        return true
                    }else{
                        return createError({message:"Please enter sale price"}) 
                    }
                }
            }),
            basicPrice: Yup.string().test("check",(values, {parent, createError})=>{
                if(parent?.multiVariant){
                    return true
                }else{
                    if (parent?.salePrice) {
                        if (common.handGstORamount(parent.salePrice, parent.gst) <= Number(values)) {
                            return true
                        } else {
                            return createError({message:"Mrp must be greater than sale price + gst"})
                        }
                    } else {
                        return true
                    }
                }
            }),
            unitId: Yup.string().required("Please select unit"),
            gst: Yup.string().required("Please select gst"),
            // brand: Yup.string().required("Please enter keyword/similar word"),
            stockStatus: Yup.boolean(),
            isGolden: Yup.boolean(),
            moq: Yup.string().test('moq', "Please enter quantity", (values, data) => {
                if (data?.parent?.isGolden == true) {
                    if (values?.trim() == "" || values == 0 || values == undefined) {
                        return false
                    } else {
                        return true
                    }
                } else {
                    return true
                }
            }),
            allowReturn: Yup.boolean(),
            multiVariant: Yup.boolean().required("Please enter brand"),
            images: Yup.array().test("check",(imData, {parent, createError})=>{
                if(parent?.multiVariant){
                    return true
                }else{
                    console.log(imData)
                    if(imData?.length>0){
                        console.log(imData);
                        return true
                    }else{
                        return createError({message:"Please choose atleast one product image"}) 
                    }
                }
            }),
                // .min(1, "Please choose atleast one product image")
                // .required("Please choose atleast one product image"),

                variants:Yup.array().of(Yup.object().shape({
                    size:Yup.string().test("check", (value, other)=>common.isRequired('size', 1, value, other, "Please enter size")),
                    // size:Yup.string().test("check", (value, other)=>common.isRequired(1, value, other, "Please enter size")),
                    description:Yup.string(),
                    colors:Yup.array().of(Yup.object().shape({
                        name:Yup.string().test("check", (value, other)=>common.isRequired('name', 2, value, other, "Please enter name")),
                        basicPrice:Yup.string().test("check", (value, other)=>common.isRequired('basicPrice', 2, value, other, "Please enter basic price")),
                        salePrice:Yup.string().test("check", (value, other)=>common.isRequired('salePrice', 2, value, other, "Please enter sale price")),
                        images:Yup.array().test("check", (value, other)=>common.isImageRequired(2, value, other, "Please choose atleast one product image")),
                    }))
                }))
            })

        return ProductValidatonSchema
    },
    
    isRequired:(name, index, value, option, message)=>{
        const {from, createError, parent} = option
        if(!from[index]?.value?.multiVariant){
            return true
        }else{
            if(value){
                if (name == 'basicPrice') {
                    if (common.handGstORamount(parent.salePrice, from[index]?.value?.gst) <= Number(value)) {
                        return true
                    } else {
                        return createError({message:"Mrp must be greater than sale price + gst"})
                    }
                } else {
                    return true
                }
            }else{
                return createError({message:message})
            }
        };
    },
    isImageRequired:(index, value, option, message)=>{
        const {from, createError} = option
        if(!from[index]?.value?.multiVariant){
            return true
        }else{
            if(value){
                if(value?.length > 0){
                    return true
                }else{
                    return createError({message:message})
                }
                console.log(value)
                
            }else{
                return createError({message:message})
            }
        };
    },

    modifyProductValues: (values) => {
        values.salePrice = Number(values?.salePrice)
        values.gst = Number(values?.gst)
        if (values.isGolden == true) {
            values.moq = Number(values.moq)
        } else {
            values.moq = ""
        }
        if (values.basicPrice) {
            values.basicPrice = Number(values.basicPrice)
        } else {
            delete values.basicPrice
        }
        return values
    },

    modifyServiceValues: (values) => {
        values.salePrice = Number(values?.salePrice)
        if (values.basicPrice) {
            values.basicPrice = Number(values.basicPrice)
        } else {
            delete values.basicPrice
        }
        values.gst = Number(values?.gst)
        return values
    },

    checkEndDate: (startdate, enddate) => {
        let temp = false;
        if (enddate) {
            return temp = new Date(startdate)?.getTime() > new Date(enddate)?.getTime()
        } else {
            return
        }

    },
    removeLable: (data) => {
        let temp = []
        data?.map((val) => (temp.push(val?.value)))
        return temp
    },

    modifyAccordingSelect: (data, subCategory) => {
        // console.log("data", data)
        // console.log("subCategorysubCategorysubCategory", subCategory)
        let temp = []
        data?.map((d) => {
            subCategory?.map((s) => {
                if (s.value == d) {
                    temp.push(s)
                }
            })
        })
        return temp
    },

    serviceUnit: () => {
        return ['Bathroom', 'Day', 'Hour', 'Kitchen', 'Meter', 'Running Feet', 'Square Feet', 'Unit', 'Visit']
    },

    ProductUnit: () => {
        return ["Bag", "Box", "Cubic Feet", "Feet", "Gauge", "Kg", "Running Feet", "Square Feet", "Unit"]
    },

    checkSelected: (catid, ids, key) => {
        let check = true;
        catid?.map((value, id) => {

            if (value?.category == ids) {
                if (key != id) {
                    check = false
                }
            }
        })
        return check
    },

    checkRenewDate: (userSub) => {
        let temp = false
        if (userSub?.subscription?.id?.subscription?.id) {
            if (userSub?.renewedPlan != "active") {
                let now = new Date(userSub?.subscription?.end)
                let lastWeekDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6)
                if (new Date().getTime() >= lastWeekDate.getTime()) {
                    temp = true
                }
            }
        }
        else {
            temp = true
        }
        return temp

    },


    orderStatus: (value) => {


        if (value) {

            if (value == 1 || value == "orderRequested") {
                return { name: 'orderRequested', value: 1, fullname: "Order Request" }
            }
            else if (value == 2 || value == "pending") {
                return { name: 'pending', value: 2, fullname: "Pending" }
            }
            else if (value == 3 || value == "inTransit") {
                return { name: 'inTransit', value: 3, fullname: "In Transit" }
            }
            else if (value == 4 || value == "delivered") {
                return { name: 'delivered', value: 4, fullname: "Delivered" }
            }
            else if (value == 5 || value == "cancelledByMe") {
                return { name: 'cancelledByMe', value: 5, fullname: "Cancelled By Customer" }
            }
            else if (value == 6 || value == "cancelledByVendor") {
                return { name: 'cancelledByVendor', value: 6, fullname: "Cancelled By Vendor" }
            }
            else if (value == 7 || value == "returnRequested") {
                return { name: 'returnRequested', value: 7, fullname: "Return Request" }
            }
            else if (value == 8 || value == "itemDispatchedByMe") {
                return { name: 'itemDispatchedByMe', value: 8, fullname: "Item Dispatched by Customer" }
            }
            else if (value == 9 || value == "itemReceivedByVendor") {
                return { name: 'itemReceivedByVendor', value: 9, fullname: "Item Recieved by Vendor" }
            }
            else if (value == 10 || value == "refundProvided") {
                return { name: 'refundProvided', value: 10, fullname: "Refund Provided" }
            }
        }

    },

    changeLabel: (status) => {
        status = common.orderStatus(status)?.value
        if (status == 1 || status == 2 || status == 3 || status == 7 || status == 8 || status == 9) {
            return "Change Status"
        }
        else if (status == 4 || status == 10) {
            return 'Status'
        }
        else if (status == 5 || status == 6) {
            return 'Cancelled By'
        }

    },

    checkDecimals: (n) => {
        if (n) {
            return n % 1 !== 0 ? `${n.toFixed(2)}` : `${n}`;
        } else {
            return 0
        }
    },

    addLabel: (arr) => {
        let temp = arr?.map((value) => ({ label: common.capitalizeWord(value), value: value }))
        return temp
    },






};

export default common;
