import React, { useContext } from 'react'
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import "rc-slider/assets/index.css";
import 'rc-tooltip/assets/bootstrap.css';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/theme';


export default function SuccessModal({ openModal, setOpenModal, msg, image, icon, btnName, extraClass }) {

    // const { signUpModal, setSignupModal } = useContext(UserContext)

    const closeModal = () => {
        setOpenModal(false)

    }

    return (
        <>

            <div className="dashheaadbtncon">
                <Modal className={`categorymodel `} show={openModal} onHide={closeModal}>

                    <Modal.Body >
                        <div className='sussesbx'>
                            <div className={`modlIcon ${extraClass && `${extraClass}`}`}>
                                {icon != ""
                                    ? <iconify-icon icon="material-symbols:check"></iconify-icon>
                                    : <span>
                                        <img src={image} alt="modal image" />
                                    </span>
                                }
                            </div>
                            <div className="categorycmodelhead">{msg}</div>
                            <div className="loginsinphone ">
                                <div className="filterprolistbtncon justify-content-center">
                                    <div className="loginsignbtn filterreset"><button type='button' onClick={closeModal} className='theme-btn-1'>{btnName}</button></div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}
