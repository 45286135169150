import React, { useContext } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import DropDownMenu, { Dropdown, Menu } from "../components/common/sidebarMenu";
import * as Yup from "yup";
import { useFormik } from "formik";
import common from "../services/common";
import { UserContext } from "../context/theme";
import { useEffect } from "react";
import pageList from "../services/pagelist";
import Translator from "../components/translator/translator";

export default function Header() {
    const history = useLocation();
    const { socket, notificationData, setNotificationData, userInfo, globalLayout, reset, employData, setRoutHistory, sideMenuActive, setSideMenuActive, windowWidth } = useContext(UserContext);
    const { id } = socket
    const [filterMenu, setFilterMenu] = useState(true);
    const tabbingMenu = (type) => {
        let newTabval = type == filterMenu ? "" : type;
        setFilterMenu(newTabval);
    };
    useEffect(() => {
        if (id) {
            socket.emit("panelNotifications", {});
            socket.on('panelNotifications', (data) => {
                setNotificationData(data || [])
            });
        }
    }, [id]);
    useEffect(() => {
        let localHistory = localStorage.getItem("history");
        if (localHistory) {
            localHistory = JSON.parse(localHistory);
        } else {
            localHistory = [];
        }
        let newRoute = {
            route: history?.pathname || "/",
            name: "",
            date: new Date(),
        };
        let pageName;
        if (history?.pathname) {
            // pageName = pageList?.filter((d) => history?.pathname.includes(d?.url))[0]?.name;
            pageName = pageList?.filter((d) => history?.pathname === d?.url)[0]?.name;
            if (history?.pathname === "/") {
                pageName = "Home";
            }
            newRoute.name = pageName;
            newRoute.date = new Date();
        }
        if (localHistory?.length > 0) {
            localHistory = localHistory?.filter((d) => d.route !== history?.pathname);
        }
        if (pageName) {
            let newHistory = [newRoute].concat(localHistory);
            let removeNum = newHistory?.length - 10;
            if (removeNum > 0) {
                newHistory.splice(-1, removeNum);
            }
            newHistory = newHistory?.sort((a, b) => new Date(b.date) - new Date(a.date))
            setRoutHistory(newHistory);
            newHistory = JSON.stringify(newHistory);
            localStorage.setItem("history", newHistory);
        }
    }, [history])
    const toggleSidebar = () => {
        setSideMenuActive(!sideMenuActive)
        if (sideMenuActive) {
            document.body.className = ""
        } else {
            document.body.className = "overflowHidn"
        }

    }



    return (

        <div className="saledesksignupWRP res">
            <div className={globalLayout ? "container" : "container-fluid"}>
                <div className="navwrp">
                    <nav className="navbar">
                        <div className="seldestnavimg">
                            <Link to="/dashboard">
                                {" "}
                                <img src="/assets/images/logo.png" alt="image" />
                            </Link>
                        </div>

                        <div className="saledesknavlink navheader">
                            <ul className="d-flex">
                                {/* <li className='hoverlink nav'><Link to="/about-us">About Us</Link></li> */}
                                <li className="hoverlink nav">
                                    <Link to="/about-us">About Us</Link>
                                </li>
                                <li className="hoverlink nav">
                                    <Link to="/contact-us">Contact Us</Link>
                                </li>
                                <li className="hoverlink nav">
                                    {/* <Link to="/">Language <img src="/assets/images/arrow_icon.png" className='pl-2' /></Link> */}
                                    <Translator />

                                </li>
                                <li className="cusnav">
                                    {" "}
                                    <Link to="/notification">
                                        <div className="notibell">
                                            <img className="img-fluid" src="/assets/images/bell.png" alt="image" />
                                            {/* {employData?.role ? (
                                                    notificationData?.filter((d) => (d.roles.includes(employData?.role)))?.length > 0 && (<span>{notificationData?.filter((d) => (d.roles.includes(employData?.role)))?.length > 9 ? (notificationData?.filter((d) => (d.roles.includes(employData?.role)))?.length + '+') : (notificationData?.filter((d) => (d.roles.includes(employData?.role)))?.length)}</span>)
                                                ) : (
                                                    notificationData?.length > 0 && (<span>{notificationData?.length >= 9 ? '9+' : notificationData?.length}</span>)
                                                )} */}
                                            {notificationData?.length > 0 && (<span>{notificationData?.length > 9 ? '9+' : notificationData?.length}</span>)}
                                        </div>
                                    </Link>
                                </li>
                                <li className="headeprofile cusnav">
                                    <Link to="/dashboard">
                                        <div className="dashprofiletext header">{userInfo?.profileImage?.fullUrl ? <img src={`${userInfo?.profileImage?.fullUrl}`} className="img-fluid" /> : <div>{common.getFirstLetter(userInfo?.name)}</div>}</div>
                                    </Link>
                                </li>
                                <div className="m\enushowwrp">
                                    <div className="navmenumainwrp" onClick={() => toggleSidebar()}>
                                        {sideMenuActive ? (
                                            <img className='iconmenu nav' src="/assets/images/clsoe.svg" alt="image" />
                                        ) : (
                                            <img className='iconmenu nav' src="/assets/images/menuicon.svg" alt="image" />
                                        )}

                                    </div>
                                    {/* <div className={`homemenuWRPAlt bar ${sideMenuActive ? "active" : ""}`} onClick={() => setSideMenuActive(!sideMenuActive)}></div>
                                        <div className={`homemenusidermenuwrp ${sideMenuActive ? "active" : ""}`}></div> */}
                                </div>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>

    );
}
